// General Support Functions

window.bbglobal = {

    // a place to store temporary data
    temp: {},

    // Custom DOM Events
    // * provide a standardized and efficient way for communicating across client-side components
    // * all events are triggered on the <body> element unless a specific target is provided or otherwise noted
    events: {

        // When adding additional events, please include members for name, trigger, and listen (see entityUpdated)

        // use when an entity is updated
        // (only pass a $target paramter if you know what you're doing)
        // ex: use when the New Message modal saves successfully - a Message grid could attach a handler to this event to refresh grid data
        entityUpdated: {

            // the bootstrap event naming convention is [type].[domain].[sub-domain]
            // ex: hide.bs.modal is the event that bootstrap emits when a modal is closed
            name: 'entityUpdated.bb',

            trigger: function (entityNameList, $target) {
                var event = $.Event(bbglobal.events.entityUpdated.name, {
                    entities: entityNameList
                });

                $target = $target || $('body');

                $target.trigger(event);
            },

            listen: function (callback) {
                $('body').on(bbglobal.events.entityUpdated.name, callback);
            }
        },

        // use when a tab is loaded and the parent code needs to be aware
        // ex: use when a tab loads in a wizard-style page so the parent page can update/extract data as needed
        tabInitialized: {
            name: 'tabInitialized.bb',

            // pass in any child element of the tab that was initialized
            // the event will be emitted on the parent .tab-manager
            // data is optional
            trigger: function ($tabElement, data) {

                $tabElement = $($tabElement);
                data = data || {};

                var $tabPane = $tabElement.closest('.tab-pane');

                if (!$tabPane.length) {
                    console.error('Failed to trigger ' + bbglobal.events.tabInitialized.name + ' event. Could not find .tab-pane using input provided:');
                    console.log($tabElement);
                    return;
                }

                var $tabManager = $tabElement.closest('.tab-manager');

                if (!$tabManager.length) {
                    console.error('Failed to trigger ' + bbglobal.events.tabInitialized.name + ' event. Could not find parent .tab-manager using input provided:');
                    console.log($tabElement);
                    return;
                }

                var tabId = $tabPane.data('tab-id');
                var tabManagerId = $tabManager.data('tab-manager-id');

                _.assign(data, { tabManagerId, tabId, $tabPane });

                var event = $.Event(bbglobal.events.tabInitialized.name, data);

                $tabManager.trigger(event);
            },

            // Attach a callback to a tab manager.
            // Tab ID is optional
            listen: function (tabManagerId, tabId, callback) {

                tabManagerId = tabManagerId || -1;
                tabId = tabId || -1;
                callback = callback || function (e) { console.log(e); };

                var $tabManager = $('.tab-manager[data-tab-manager-id=' + tabManagerId + ']');

                if (!$tabManager.length) {
                    console.error('Failed to listen on ' + bbglobal.events.tabInitialized.name + ' event. Could not find parent .tab-manager using input provided:');
                    console.log(tabManagerId);
                }

                $tabManager.on(bbglobal.events.tabInitialized.name, function (tabInitializedEvent) {
                    if (tabInitializedEvent.tabManagerId === tabManagerId) {
                        if (tabId < 0 || tabInitializedEvent.tabId === tabId) {
                            callback.call(this, tabInitializedEvent);
                        }
                    }
                });
            },

            // Delegate a callback
            // Tab ID is optional
            delegate: function ($target, tabManagerId, tabId, callback) {

                $target = $($target || 'body');
                callback = callback || function (e) { console.log(e); };

                $target.on(bbglobal.events.tabInitialized.name, '.tab-manager', function (tabInitializedEvent) {
                    if (tabInitializedEvent.tabManagerId === tabManagerId && tabInitializedEvent.tabId === tabId) {
                        callback.call(this, tabInitializedEvent);
                    }
                });
            }
        },

        // use when a modal is loaded and the client code needs to be aware
        modalInitialized: {
            name: 'modalInitialized.bb',

            // pass in any child element of the tab that was initialized
            // the event will be emitted on the parent .tab-manager
            trigger: function () {
                var event = $.Event(bbglobal.events.modalInitialized.name);

                bbglobal.modalManager.element().trigger(event);
            },

            listen: function (callback) {
                bbglobal.modalManager.element().on(bbglobal.events.modalInitialized.name, callback);
            }
        }
    },

    // enables/disabled grid logging, etc
    GRID_DEBUG_MODE: true,

    fixFormValidation: function ($form) {
        $($form).removeData('validator');
        $($form).removeData('unobtrusiveValidation');
        $.validator.unobtrusive.parse($($form));
    },

    printUserSession: function () {
/*    
        console.log("window.UserSession.SessionID: " + window.UserSession.SessionID);
        console.log("window.UserSession.PersonID: " + window.UserSession.PersonID);
        console.log("window.UserSession.FullName: " + window.UserSession.FullName);
        console.log("window.UserSession.UserName: " + window.UserSession.UserName);
        console.log("window.UserSession.PersonID: " + window.UserSession.PersonID);
        console.log("window.UserSession.UserCode: " + window.UserSession.UserCode);
        console.log("window.UserSession.UserCode: " + window.UserSession.UserCode);
        console.log("window.UserSession.CompanyName: " + window.UserSession.UserName);
        console.log("window.UserSession.EmployeeTitle: " + window.UserSession.EmployeeTitle);
        console.log("window.UserSession.DepartmentName: " + window.UserSession.DepartmentName);
        console.log("window.UserSession.Breadcrumbs: " + window.UserSession.Breadcrumbs);
        // impersonation values
        console.log("window.UserSession.ImpersonatingUser: " + window.UserSession.ImpersonatingUser);
        console.log("window.UserSession.ImpersonatingPersonID: " + window.UserSession.ImpersonatingPersonID);
        // role flags
        console.log("window.UserSession.IsEmployee: " + window.UserSession.IsEmployee);
        console.log("window.UserSession.IsAdmin: " + window.UserSession.IsAdmin);
        console.log("window.UserSession.IsPartner: " + window.UserSession.IsPartner);
        console.log("window.UserSession.IsCoCounsel: " + window.UserSession.IsCoCounsel);
        console.log("window.UserSession.IsAgent: " + window.UserSession.IsAgent);
        console.log("window.UserSession.IsOutsideCallCenter: " + window.UserSession.IsOutsideCallCenter);
        console.log("window.UserSession.IsIntakeAccess: " + window.UserSession.IsIntakeAccess);
        console.log("window.UserSession.IsClient: " + window.UserSession.IsClient);
        console.log("window.UserSession.IsAdvancedUser: " + window.UserSession.IsAdvancedUser);
        // time values
        console.log("window.UserSession.AutoTrackTime: " + window.UserSession.AutoTrackTime);
        console.log("window.UserSession.ActiveTimeEntryID: " + window.UserSession.ActiveTimeEntryID);
        console.log("window.UserSession.ActiveTimeEntryStart: " + window.UserSession.ActiveTimeEntryStart);

        console.log("window.UserSession.PinnedReport: " + window.UserSession.PinnedReport);
        console.log("window.UserSession.Restricted: " + window.UserSession.Restricted);
        console.log("window.UserSession.DeviceSize: " + window.UserSession.DeviceSize);
        console.log("window.UserSession.DeviceOrientation: " + window.UserSession.DeviceOrientation);

        console.log("window.UserSession.LocationImagePath: " + window.UserSession.LocationImagePath);
        console.log("window.UserSession.ProfileImagePath: " + window.UserSession.ProfileImagePath);

        for (i = 0; i < window.UserSession.Roles.length; i++) {
            console.log("window.UserSession.Role: " + window.UserSession.Roles[i]);
        }

        //public IList < int > CaseTypes { get; set; }
        //public IList < int > MatterExclusions { get; set; }
        //public IList < int > MatterInclusions { get; set; }
*/
    },

    escapeRegExp: function (str) {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    },

    // used to replace all instance of find with replace in str, not just the first
    replaceAll: function (str, find, replace) {
        return str.replace(new RegExp(bbglobal.escapeRegExp(find), 'g'), replace);
    },

    serializePersonalDetails: function (formId) {

        var data = {
            Addresses: addresses,
            EmailAddresses: emails,
            PhoneNumbers: phones,
            Tags:tags
        };

        let v1 = $('#PersonGID');
        let v2 = $('#PersonGIDHidden');
        if (v1 && v2) {
            if (v1.val() !== v2.val()) $('#EditedGID').val('True');
        }

        $(formId).serialize().split('&').forEach(function (token) {
            var keyValue = token.split('=');
            data[keyValue[0]] = decodeURIComponent(keyValue[1] || '');
        });

        data.__RequestVerificationToken = undefined;

        return data;
    },

    formatPhoneNumber: function (phoneNumberString) {
        var cleaned = ("" + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        return !match ? "" : "(" + match[1] + ") " + match[2] + "-" + match[3];
    },
    formatCurrency: function (number) {
        decPlaces = 2;
        decSep = ".";
        thouSep = ",";
        var sign = number < 0 ? "-" : "";
        var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
        var j = (j = i.length) > 3 ? j % 3 : 0;

        return sign + " $ " +
            (j ? i.substr(0, j) + thouSep : "") +
            i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
            (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
    },
/*
    formatPhoneNumber: function (phoneNumber) {
        var s, m;
        if (phoneNumber.length <= 5) {
            s = ('' + phoneNumber).replace(/\D/g, '');
            m = s.match(/^(\d{3})$/);

            return !m ? phoneNumber : '(' + m[1] + ') ';
        }
        else if (phoneNumber.length > 5 && phoneNumber.length <= 9) {
            s = ("" + phoneNumber).replace(/\D/g, '');
            m = s.match(/^(\d{3})(\d{3})$/);

            return !m ? phoneNumber : "(" + m[1] + ") " + m[2] + "-";
        }
        else if (phoneNumber.length > 9) {
            s = ("" + phoneNumber).replace(/\D/g, '');
            m = s.match(/^(\d{3})(\d{3})(\d{4})$/);

            return !m ? phoneNumber : "(" + m[1] + ") " + m[2] + "-" + m[3];
        }
        else {
            return phoneNumber;
        }
    },
*/
    hideGrid: function (id, noRecords) {
        if (bbglobal.GRID_DEBUG_MODE) console.log('[' + id + ']: hideGrid');
        $('#grid-' + id + ' > table').hide();
        $('#grid-' + id + ' > .k-grid-header').hide();
        $('#grid-' + id + ' > .k-grid-content').hide();
        $('#grid-' + id + ' > .k-pager-wrap').hide();

        if (noRecords) $('#no-records-msg-' + id).show();
    },

    antiforgeryToken: function () {
        return $("input[name=__RequestVerificationToken]").val();
    },

    formatRatio: function(num, denom, format) {

        // ratio does not exist
        if (num === undefined || denom === undefined || denom === 0)
            return ' - '; 

        var ratio = num / denom;

        if (format !== undefined)
            return kendo.toString(ratio, format);

        return ratio.toString();
    },

    applyFiltersToWidget: function (widgetId, filterId) {
        var filters = $('#filters-' + filterId).data('parseFilters')();

        if (filters === undefined || filters === null)
            return;

        var dataSource = $('#widget-' + widgetId).data('dataSource');

        if (dataSource !== undefined) {
            dataSource.filter(filters);
        } else {
            var grid = $('#widget-' + widgetId + ' .bb-kendo-grid');
            if (grid.length === 1) {
                grid.data('kendoGrid').dataSource.filter(filters);
            }
        }
    },

    applyFiltersToGrid: function (gridId, filterId) {
        var $filters = $('#filters-' + filterId);
        var gridFilters = $filters.data('parseFilters')();

        if (gridFilters === undefined || gridFilters === null)
            return false;

        var favoritesOnly = $filters.find('input[name="FavoritesOnly"]').prop('checked');
        var recentOnly = $filters.find('input[name="RecentOnly"]').prop('checked');

        // adjust data pulled by the grid
        $('#grid-' + gridId).data('favoritesOnly', favoritesOnly);
        $('#grid-' + gridId).data('recentOnly', recentOnly);

        var grid = $('#grid-' + gridId).data('kendoGrid');
        // this function alters multiple dataSource settings without making multiple server requests
        // any changes that require a request will trigger once all changes have been applied
        grid.dataSource.query({

            // overwrite current filters with user's
            filter: gridFilters,

            // revert to original page size
            pageSize: $('#grid-' + gridId).data('initialPageSize'),

            // first page of filter results
            page: 1,

            // maintain other settings
            sort: grid.dataSource.sort(),
            aggregate: grid.dataSource.aggregate(),
            group: grid.dataSource.group()
        });

        return true;
    },

    appendArrayToFormData: function (formData, array, prefix = '') {
        for (var i = 0; i < array.length; i++) {
            var element = array[i];
            var key = prefix + '[' + i + ']';

            bbglobal.appendObjectToFormData(formData, element, key);
        }

        return formData;
    },

    appendObjectToFormData: function (formData, data, prefix = '') {
        for (var prop in data) {

            var key = prop;

            if (prefix.length)
                key = prefix + '.' + key;

            if (Object.prototype.hasOwnProperty.call(data, prop)) {
                if (Array.isArray(data[prop])) {
                    formData = bbglobal.appendArrayToFormData(formData, data[prop], key);
                } else if (typeof data[prop] === 'object') {
                    formData = bbglobal.appendObjectToFormData(formData, data[prop], key);
                } else {
                    formData.append(key, data[prop]);
                }
            }
        }

        return formData;
    },

    timer: function (element, startTime, styleNumber) {
        var start = new Date(startTime).getTime();
        var cdate = new Date();
        var mathTrunc = function (x) {
            if (isNaN(x)) {
                return NaN;
            }
            if (x > 0) {
                return Math.floor(x);
            }
            return Math.ceil(x);
        };
        var intervalFunc = function () {
            var now = new Date().getTime();
            var difference = styleNumber === 4 ? start - now : now - start;

            //var days = start === 0 ? 0 : difference > 0 ? Math.floor(difference / (1000 * 60 * 60 * 24)) : Math.trunc(difference / (1000 * 60 * 60 * 24)) ;
            //var hours = start === 0 ? 0 : difference > 0 ? Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) : Math.trunc((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            //var minutes = start === 0 ? 0 : difference > 0 ? Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)) : Math.trunc((difference % (1000 * 60 * 60)) / (1000 * 60)) ;
            //var seconds = start === 0 ? 0 : difference > 0 ? Math.floor((difference % (1000 * 60)) / 1000) : Math.trunc((difference % (1000 * 60)) / 1000);

            var days = start === 0 ? 0 : mathTrunc(difference / (1000 * 60 * 60 * 24));
            var hours = start === 0 ? 0 : mathTrunc(difference % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
            var minutes = start === 0 ? 0 : mathTrunc(difference % (1000 * 60 * 60) / (1000 * 60));
            var seconds = start === 0 ? 0 : mathTrunc(difference % (1000 * 60) / 1000);

            var msg = '';

            switch (styleNumber) {
                case 0: default:
                    if (days > 0)
                        msg = msg + days + ' d ';

                    if (hours > 0)
                        msg = msg + hours + ' hr ';

                    if (minutes > 0)
                        msg = msg + minutes + ' min ';

                    if (seconds > 0)
                        msg = msg + seconds + ' sec';
                    break;

                case 1:
                    if (days > 0)
                        msg = msg + days + ':';

                    if (hours > 9)
                        msg = msg + hours + ':';
                    else if (hours > 0)
                        msg = msg + '0' + hours + ':';
                    else
                        msg = msg + '00' + ':';

                    if (minutes > 9)
                        msg = msg + minutes + ':';
                    else if (minutes > 0)
                        msg = msg + '0' + minutes + ':';

                    if (seconds > 9)
                        msg = msg + seconds;
                    else if (seconds > 0)
                        msg = msg + '0' + seconds;
                    else
                        msg = msg + '00';
                    break;

                case 2:
                    hours += days * 24;

                    if (hours > 9)
                        msg = msg + hours + ':';
                    else if (hours > 0)
                        msg = msg + '0' + hours + ':';
                    else
                        msg = msg + '00' + ':';

                    if (minutes > 9)
                        msg = msg + minutes + ':';
                    else if (minutes > 0)
                        msg = msg + '0' + minutes + ':';

                    if (seconds > 9)
                        msg = msg + seconds;
                    else if (seconds > 0)
                        msg = msg + '0' + seconds;
                    else
                        msg = msg + '00';
                    break;

                case 3:
                    hours += days * 24;
                    if (hours > 0)
                        msg = msg + hours + ' hr ';

                    if (minutes > 0)
                        msg = msg + minutes + ' min ';
                    break;

                case 4:
                    if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) {
                        if (days > 0) {
                            if (days > 1)
                                if(hours >= 10) //add one more day
                                    msg = 'Scheduled in ' + (1 + days) + ' days ';
                                else
                                    msg = 'Scheduled in ' + days + ' days ';
                            else
                                if (hours >= 10)
                                    msg = 'Scheduled in ' + (1 + days) + ' days ';
                                else
                                    msg =  'Scheduled tomorrow';
                        }
                        else {

                            if (hours > 0) {
                                var chr = cdate.getHours();
                                var hrs = new Date(start).getHours();
                                var min = new Date(start).getMinutes();
                                console.log('hrs: ' + hrs);
                                if (hours + chr > 23) {
                                    if (hrs < 12)
                                        msg = 'Scheduled tomorrow at ' + hrs + ':' + min + ' AM ';
                                    if (hrs === 12)
                                        msg = 'Scheduled tomorrow at 12 :' + min + ' PM ';
                                    if (hrs > 12)
                                        msg = 'Scheduled tomorrow at ' + (hrs-12) + ':' + min + ' PM ';
                                }
                                else
                                {
                                    if(hrs < 12)
                                        msg = 'Scheduled at ' + hrs + ':' + min + ' AM ';
                                    if (hrs === 12)
                                        msg = 'Scheduled at 12 :' + min + ' PM ';
                                    if (hrs > 12)
                                        msg = 'Scheduled at ' + (hrs -12) + ':' + min + ' PM ';
                                }

                            }
                            else {
                                if (minutes > 0)
                                    msg = 'Scheduled in ' + minutes + ' min ';
                                else {
                                    if (seconds > 0)
                                        msg = 'Due now';
                                }
                            }
                        }
                    }
                    else {
                        if (-1 * days > 0) {
                            if (-1 * days > 1)
                                msg = 'Overdue by ' + days * -1  + ' days ';
                            else
                                msg =  'Overdue by 1 day';
                        }
                        else {
                            if (-1 * hours > 0)
                                msg = 'Overdue by ' + hours * -1 + ' hr ';
                            else {
                                if (-1 * minutes > 0)
                                    msg = 'Overdue by ' + minutes * -1 + ' min ';
                                else {
                                    if (-1 * seconds > 0)
                                        msg = 'Due now';
                                }
                            }
                        }

                    }
                    break;
            }

            $(element).text(msg);
        };

        var x = setInterval(intervalFunc.bind(element), 1000);
    },

    showGrid: function (id) {
        if (bbglobal.GRID_DEBUG_MODE) console.log('[' + id + ']: showGrid');
        $('#grid-' + id + ' > table').show();
        $('#grid-' + id + ' > .k-grid-header').show();
        $('#grid-' + id + ' > .k-grid-content').show();
        $('#grid-' + id + ' > .k-pager-wrap').show();

        $('#no-records-msg-' + id).hide();
    },

    gridLookup: function(form, url, saveCallback) {
        $(form).parent().append('<div class="lookup-overlay"><div id="lookup-content"></div></div>');
        $.ajax({
            url: url,
            type: 'GET',
            success: function (data) {
                $('#lookup-content').html(data);

                $('#lookup-content .lookup-save').click(function (e) {
                    saveCallback.call(this);
                });

                $('#lookup-content .lookup-cancel').click(function (e) {
                    $('#lookup-content').closest('.lookup-overlay').remove();
                });
            }
        });
    },

    alert: {
        info: function (message, append = false) {
            if (append) {
                $('#bbglobal-alert-anchor').append('<div class="alert alert-info alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            } else {
                $('#bbglobal-alert-anchor').html('<div class="alert alert-info alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            }
            $('#bbglobal-alert-anchor').show();
        },

        success: function (message, append = false) {
            if (append) {
                $('#bbglobal-alert-anchor').append('<div class="alert alert-success alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            } else {
                $('#bbglobal-alert-anchor').html('<div class="alert alert-success alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            }
            $('#bbglobal-alert-anchor').show();
        },

        warning: function (message, append = false) {
            if (append) {
                $('#bbglobal-alert-anchor').append('<div class="alert alert-warning alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            } else {
                $('#bbglobal-alert-anchor').html('<div class="alert alert-warning alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            }
            $('#bbglobal-alert-anchor').show();
        },

        error: function (message, append = false) {
            if (append) {
                $('#bbglobal-alert-anchor').append('<div class="alert alert-danger alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            } else {
                $('#bbglobal-alert-anchor').html('<div class="alert alert-danger alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            }
            $('#bbglobal-alert-anchor').show();
        },

        clear: function () {
            $('#bbglobal-alert-anchor').html('');
            $('#bbglobal-alert-anchor').show();
        },

        jsonResponse: function (ViewResponse = { Error: true }, defaultSuccess = 'Success', defaultError = 'Error') {
            successMessage = ViewResponse.Message || defaultSuccess;
            errorMessage = ViewResponse.Message || defaultError;

            if (ViewResponse.Error) {
                bbglobal.alert.error(errorMessage);
            } else {
                bbglobal.alert.success(successMessage);
            }

            return !ViewResponse.Error;
        }
    },
    modalAlert: {
        info: function (message, append = false) {
            if (append) {
                $('#bbglobal-alert-modal').append('<div class="alert alert-info alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            } else {
                $('#bbglobal-alert-modal').html('<div class="alert alert-info alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            }
            $('#bbglobal-alert-modal').show();
        },

        success: function (message, append = false) {
            if (append) {
                $('#bbglobal-alert-modal').append('<div class="alert alert-success alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            } else {
                $('#bbglobal-alert-modal').html('<div class="alert alert-success alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            }
            $('#bbglobal-alert-modal').show();
        },

        warning: function (message, append = false) {
            if (append) {
                $('#bbglobal-alert-modal').append('<div class="alert alert-warning alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            } else {
                $('#bbglobal-alert-modal').html('<div class="alert alert-warning alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            }
            $('#bbglobal-alert-modal').show();
        },

        error: function (message, append = false) {
            if (append) {
                $('#bbglobal-alert-modal').append('<div class="alert alert-danger alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            } else {
                $('#bbglobal-alert-modal').html('<div class="alert alert-danger alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            }
            $('#bbglobal-alert-modal').show();
        },

        clear: function () {
            $('#bbglobal-alert-modal').html('');
            $('#bbglobal-alert-modal').show();
        },

        jsonResponse: function (ViewResponse = { Error: true }, defaultSuccess = 'Success', defaultError = 'Error') {
            successMessage = ViewResponse.Message || defaultSuccess;
            errorMessage = ViewResponse.Message || defaultError;

            if (ViewResponse.Error) {
                bbglobal.alert.error(errorMessage);
            } else {
                bbglobal.alert.success(successMessage);
            }

            return !ViewResponse.Error;
        }
    },

    alertTo: {
        info: function (div,message) {
            $(div).html('<div class="alert alert-info alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            $(div).show();
        },

        success: function (div,message) {
            $(div).html('<div class="alert alert-success alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            $(div).show();
        },

        warning: function (div,message) {
            $(div).html('<div class="alert alert-warning alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            $(div).show();
        },

        error: function (div,message) {
            $(div).html('<div class="alert alert-danger alert-dismissable"><a class="close" data-dismiss="alert">×</a><span>' + message + '</span></div>');
            $(div).show();
        },

        clear: function (div) {
            $(div).html('');
            $(div).show();
        }
    },

    modalManager: {

        setTitle: function(title) {
            $('#modalCard-title').text(title);
        },

        show: function() {
            $("#modalCard").modal({
                show: true,
                backdrop: 'static'
            });

            // TEMPORARY
            $('.modal-backdrop').click(function () { $(this).hide(); });
        },

        setWidth: function(maxWidth, width = '80%') {
            $('#modalCard-contentTarget').closest('.modal-dialog').css('max-width', maxWidth);
            $('#modalCard-contentTarget').closest('.modal-dialog').css('width', width);
        },

        hide: function() {
            $('#modalCard').modal('hide');
        },

        loading: function () {
            // depreciated
        },

        setContent: function(content) {
            $('#modalCard #modalCard-contentTarget').html(content);
        },

        enableClose: function (enabled = true) {
            if (enabled)
                $("#modalCard").find('button.close').show();
            else
                $("#modalCard").find('button.close').hide();
        },

        reset: function (clearTitle = true) {

            if (clearTitle)
                bbglobal.modalManager.setTitle('');

            bbglobal.modalManager.setWidth('unset');
            bbglobal.modalManager.setContent('');
            bbglobal.modalManager.enableClose();
            $('#modalCard #bbglobal-alert-modal').html('');
        },

        load: function (url, title, data, method) {

            if (!url) {
                bbglobal.modalManager.setContent("Cannot load modal without a URL.");
                return;
            }

            bbglobal.modalManager.reset();

            title = title || '';
            data = data || {};
            bbglobal.modalManager.setTitle(title);

            _.assign(data, { modal: true });

            $.ajax({
                url: url,
                datatype: 'json',
                type: method || 'GET',
                data: data,
                cache: false,
                success: function (data) {
                    if (data.Error) {
                        bbglobal.alert.error(data.Message);
                    }
                    else {
                        bbglobal.modalManager.reset(false);
                        bbglobal.modalManager.setContent(data);
                        bbglobal.modalManager.show();
                    }
                },
                error: function (xhr, status, error) {
                    bbglobal.alert.error('Could not load modal : ' + error);
                    console.log(error);
                }
            });
        },

        element: function () { return $('#modalCard'); },

        contentElement: function () { return $('#modalCard #modalCard-contentTarget'); },

        confirm: function (title, message, confirmText, denyText, confirmCallback, denyCallback) {
            title = title || 'Confirmation';
            message = message || 'Confirm?';
            confirmText = confirmText || 'Yes';
            denyText = denyText || 'No';
            confirmCallback = confirmCallback || bbglobal.modalManager.hide;
            denyCallback = denyCallback || bbglobal.modalManager.hide;

            bbglobal.modalManager.setTitle(title);
            //bbglobal.modalManager.setWidth('300px');

            $('#modalCard-contentTarget')
                .html('<p class="confirm-modal-message">' + message + '</p>')
                .append('<div class="modal-footer"><button class="btn btn-primary modal-confirm" type="button">' + confirmText + '</button><button class="btn btn-secondary modal-deny" type="button">' + denyText + '</button></div>');
            $('#modalCard-contentTarget .modal-confirm').click(confirmCallback);
            $('#modalCard-contentTarget .modal-deny').click(denyCallback);

            bbglobal.modalManager.show();
        },

        configs: [],

        add: function (url, title, data, modalType) {
            var config = {
                title: title,
                url: url,
                data: data,
                modalType: modalType
            };

            bbglobal.modalManager.configs.push(config);
        },

        addQuickAction: function (modalType, title, url, data) {
            bbglobal.modalManager.add(url, title, data, modalType);
            var configId = bbglobal.modalManager.configs.length - 1;

            $('.card-actions .dropdown-item[data-modalType="' + modalType + '"]').click(function () {
                bbglobal.modalManager.loadConfig(configId);
            });
        },

        addQuickActionWithConfirmation: function (modalType, title, url, data, ajaxMethod, confirmMessage, confirmText, denyText) {
            confirmTitle = title || 'Confirmation';
            confirmMessage = confirmMessage || 'Confirm?';
            confirmText = confirmText || 'Yes';
            denyText = denyText || 'No';

            $('.card-actions .dropdown-item[data-modalType="' + modalType + '"]').click(function (e) {
                bbglobal.modalManager.setTitle(confirmTitle);
                $('#modalCard-contentTarget')
                    .html('<p class="confirm-modal-message">' + confirmMessage + '</p>')
                    .append('<div class="modal-footer"><button class="btn btn-primary modal-confirm" type="button">' + confirmText + '</button><button class="btn btn-secondary modal-deny" type="button">' + denyText + '</button></div>');
                bbglobal.modalManager.show();

                $('#modalCard-contentTarget .modal-confirm').click(function () {

                    data.__RequestVerificationToken = bbglobal.antiforgeryToken();

                    $.ajax({
                        url: url,
                        datatype: 'json',
                        type: ajaxMethod || 'GET',
                        data: data,
                        cache: false,
                        success: function (data) {
                            if (data.Error) {
                                bbglobal.alert.error(data.Message || 'Unknown error occurred');
                            }
                            else {
                                bbglobal.alert.success(data.Message || 'Success');
                            }

                            bbglobal.modalManager.hide();
                        },
                        error: function (xhr, status, error) {
                            bbglobal.alert.error('Unknown error occurred');
                            console.log(error);

                            bbglobal.modalManager.hide();
                        }
                    });
                });

                $('#modalCard-contentTarget .modal-deny').click(function(){
                    bbglobal.modalManager.hide();
                });
            });
        },

        loadConfig: function (id) {
            var config = bbglobal.modalManager.configs[id];

            bbglobal.modalManager.load(config.url, config.title, config.data);
        }
    },

    textareaEditor: function (container, options) {
        $('<textarea data-bind="value: ' + options.field + '" cols="80" rows="20"></textarea>').appendTo(container);
    },

    ConvertFormToJSON: function(form) {
        var array = $(form).serializeArray();
        var json = {};
    
        jQuery.each(array, function() {
            json[this.name] = this.value || '';
        });
    
        return json;
    },

    isEmptyOrSpaces: function (str) {
        return str === null || str.match(/^ *$/) !== null;
    },

    setGridView: function (gridId, view, read) {
        if (bbglobal.GRID_DEBUG_MODE) console.log('[' + gridId + ']: setGridView(' + view + ')');
        if (read === undefined)
            read = true;

        var grid = $('#grid-' + gridId).data('kendoGrid');
        $('#grid-views-' + gridId + ' .view-menu-target').text(view);
        $('#grid-' + gridId).data('grid-view', view);
        grid.dataSource.data([]);
        $('#no-records-msg-' + gridId).hide();
        if (read) {
            grid.dataSource.pageSize($('#grid-' + gridId).data('initialPageSize'));
        }
    },

    isDataDirty: function () { console.log("isDataDirty"); },

    saveCardData: function () { console.log("saveCardData"); },

    reloadActiveHeaderDetail: function () {
        $('.detail-header .tabstrip a.active').click();
    },

    toggleAdvancedSearch: function (id, grid, show) {
        if (bbglobal.GRID_DEBUG_MODE) console.log('[' + id + ']: toggleAdvancedSearch');
        $('#grid-' + id).data('advancedSearch', show);

        if (show) {
            var currentView = $('#grid-' + id).data('grid-view');
            if (currentView !== 'Default') {
                $('#grid-' + id).data('prev-grid-view', currentView);
                $('#grid-views-' + id + ' .grid-view-Default').click();
            }
            $('#adv-search-pane-' + id).show();
            $('#search-' + id).hide();
            $('#new-' + id).hide();
            $('#search-' + id).val('');
        } else {
            var prevView = $('#grid-' + id).data('prev-grid-view');
            if (prevView) {
                $('#grid-views-' + id + ' .grid-view-' + prevView).click();
                $('#grid-' + id).data('prev-grid-view', null);
            }
            $('#adv-search-pane-' + id).hide();
            $('#search-' + id).show();
            $('#new-' + id).show();
        }
    },

    toggleGrouping: function (id, grid, show) {
        if (bbglobal.GRID_DEBUG_MODE) console.log('[' + id + ']: toggleGrouping');
        if (show) {
            $('#grid-' + id + ' > .k-grouping-header').show();

            $('#grouping-btns-' + id).show();
        } else {
            $('#grid-' + id + ' > .k-grouping-header').hide();

            $('#grouping-btns-' + id).hide();
        }
    },

    advancedSearchData: [],

    executeSearch: function (id) {
        if (bbglobal.GRID_DEBUG_MODE) console.log('[' + id + ']: executeSearch');

        var filters = $('#filters-' + id);

        if (filters.length > 0) {
            bbglobal.applyFiltersToGrid(id, id);
        } else {
            var grid = $('#grid-' + id).data('kendoGrid');

            if (grid.dataSource.pageSize() !== $('#grid-' + id).data('initialPageSize'))
                grid.dataSource.pageSize($('#grid-' + id).data('initialPageSize'));
            else if (grid.pager.page() > 1)
                grid.pager.page(1);
            else
                grid.dataSource.read();
        }
    },

    getAdvancedSearchData: function (id) {
        if (bbglobal.GRID_DEBUG_MODE)
            console.log('[' + id + ']: getAdvancedSearchData');
        $('#adv-search-grid-' + id).data('kendoGrid').dataSource.sync();
        var data = [];

        for (var i = 0; i < bbglobal.advancedSearchData[id].length; i++) {
            // only send records with criterion values, unless values are not needed
            if (bbglobal.advancedSearchData[id][i].Value !== null && bbglobal.advancedSearchData[id][i].Value.length > 0 ||
                bbglobal.advancedSearchData[id][i].Operator.value === 'isempty' ||
                bbglobal.advancedSearchData[id][i].Operator.value === 'isnotempty' ||
                bbglobal.advancedSearchData[id][i].Operator.value === 'isnull' ||
                bbglobal.advancedSearchData[id][i].Operator.value === 'isnotnull') {

                var entry = {
                    field: bbglobal.advancedSearchData[id][i].Field,
                    value: bbglobal.advancedSearchData[id][i].Value,
                    op: bbglobal.advancedSearchData[id][i].Operator.value,
                    type: bbglobal.advancedSearchData[id][i].Type
                };

                if (entry.type === 'Date') {
                    entry.value = kendo.toString(kendo.parseDate(entry.value), 'G');
                }

                data.push(entry);
            }
        }

        return data;
    },
    
    resetAdvancedSearch: function (id) {
        if (bbglobal.GRID_DEBUG_MODE) console.log('[' + id + ']: resetAdvancedSearch');
        var searchGrid = $('#adv-search-grid-' + id).data('kendoGrid');

        jQuery.each(bbglobal.advancedSearchData[id], function (x) {
            x.Value = '';
            x.Operator = { text: '=', value: 'eq' };
        });

        searchGrid.dataSource.read();
        bbglobal.hideGrid(id, false);
    },

    loadMap: function (personId, container) {
        $(container).empty();

        $.ajax({
            type: 'get',
            url: '/Employee/People/LocationMap',
            data: { personId: personId },
            success: function (data) {
                // load HTML
                $(container).html(data);

                // execute any scripts contained within
                $(container).find('script').each(function () {
                    eval($(this).text());
                });
            },
            error: function () {
                $(container).html($('<p>Location not found.</p>'));
            }
        });
    },

    MakeReadOnly: function () {
        for (x = 0; x < document.getElementsByTagName('input').length; x++) {
            document.getElementsByTagName('input').item(x).readOnly = true;
        }
        for (x = 0; x < document.getElementsByTagName('textarea').length; x++) {
            document.getElementsByTagName('textarea').item(x).readOnly = true;
        }
        for (x = 0; x < document.getElementsByTagName('checkbox').length; x++) {
            document.getElementsByTagName('checkbox').item(x).readOnly = true;
        }
        for (x = 0; x < document.getElementsByTagName('select').length; x++) {
            document.getElementsByTagName('select').item(x).disabled = true;
        }
    },

    formToObject: function (form) {
        return bbglobal.objectifyFormArray($(form).serializeArray());
    },

    objectifyFormArray: function(formArray) {
        var returnArray = {};

        for (var i = 0; i < formArray.length; i++)
            returnArray[formArray[i]['name']] = formArray[i]['value'];

        return returnArray;
    },

    clearForm: function (myFormElement) {
        var elements = myFormElement.elements;

        myFormElement.reset();

        for (i = 0; i < elements.length; i++) { 

            field_type = elements[i].type.toLowerCase();

            switch (field_type) {

                case "text":
                case "password":
                case "textarea":
                case "hidden":
                case "email":
                    elements[i].value = "";
                    break;

                case "radio":
                case "checkbox":                    
                    if (elements[i].checked) {
                        elements[i].checked = false;
                    }
                    break;

                case "select-one":
                case "select-multi":
                    elements[i].selectedIndex = -1;
                    break;

                default:
                    break;
            }
        }
    },

    MakeDetailReady: function () {
        //  Set Focus on an input control w/ TabIndex=1
        $('input[tabindex=1]').focus();

        //  Button Toggle Initialization
        $('.btn-toggle').click(function (e) {

            // the data-value for the input for this span element
            var value = $(this).find('input[type="radio"]').attr('data-value');

            //find the hidden field for the div
            var inputfield = $(this).siblings('input[type="hidden"]');

            var qid = $(inputfield).data("qid");

            if (inputfield.val() !== value) {
                //assign value
                inputfield.val(value);
                $(this).addClass('active');
                //removing active from other button
                $(this).siblings('.btn-toggle').removeClass('active');

                $('[data-conditionalfieldid=' + qid + '][data-conditionalfield_value="' + value + '"]').each(function(i, e) {
                    $(e).show();
                    if ($(e).hasClass('question-required')) {
                        $(e).data('val-required', 'This question is required.');
                        $(e).attr('required', 'required');
                    } else if ($(e).find('.question-required').length > 0) {
                        $(e).find('.question-required').data('val-required', 'This question is required.');
                        $(e).find('.question-required').attr('required', 'required');
                    }
                });

                //to clear fields that are not relevant if choice is changed
                if ($(document.documentElement).find('[data-conditionalfieldid=' + qid + ']').attr('data-conditionalfield_value') !== value) {
                    bbglobal.resetConditionalFields(value, qid);
                }

                $('[data-conditionalfieldid=' + qid + '][data-conditionalfield_value!="' + value + '"]').each(function(i, e) {
                    $(e).hide();
                    if ($(e).hasClass('question-required')) {
                        $(e).removeData('val-required');
                        $(e).attr('required', null);
                    } else if ($(e).find('.question-required').length > 0) {
                        $(e).find('.question-required').removeData('val-required');
                        $(e).find('.question-required').attr('required', null);
                    }
                });

                e.stopImmediatePropagation();
            }
            else {
                e.stopImmediatePropagation();
                inputfield.val('');
                $(this).removeClass('active');

                $(document.documentElement).find('[data-conditionalfieldid=' + qid + ']').hide();
            }
        });

        //  Phone Control Initialization
        $('.BBvalidate-phone')
            .keydown(function (e) {
                var key = e.charCode || e.keyCode || 0;
                $phone = $(this);

                // Auto-format- do not expose the mask as the user begins to type
                if (key !== 8 && key !== 9) {
                    if ($phone.val().length === 4) {
                        $phone.val($phone.val() + ')');
                    }
                    if ($phone.val().length === 5) {
                        $phone.val($phone.val() + ' ');
                    }
                    if ($phone.val().length === 9) {
                        $phone.val($phone.val() + '-');
                    }
                }

                // Allow numeric (and tab, backspace, delete) keys only
                return key === 8 || key === 9 || key === 46 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105);
            })

            .bind('focus click', function () {
                $phone = $(this);

                if ($phone.val().length === 0) {
                    $phone.val('(');
                }
                else {
                    var val = $phone.val();
                    $phone.val('').val(val); // Ensure cursor remains at the end
                }
            })

            .blur(function () {
                $phone = $(this);

                if ($phone.val() === '(') {
                    $phone.val('');
                }
            });
    
        // set toggle state
        bbglobal.ButtonTogglehighlightSelectedYesNos();
        bbglobal.CheckConditionalDropdowns();
    },

    updateConditionalFields: function (value, qid) {
        var mainElement = $('[data-conditionalfieldid].conditionalon_another');
        var bValue = bbglobal.stringToBoolean(value);
        if (mainElement.length > 0) {
            for (var i = 0; i < mainElement.length; i++) {
                var _qfield_id = $(mainElement[i]).data('conditionalfieldid');
                if (qid === "q_callingaboutsomeoneelse") {
                    if (bValue === true) $('[data-conditionalfieldid="q_callingaboutsomeoneelse"][data-conditionalfieldvalue="true"]').show();
                    else $('[data-conditionalfieldid="q_callingaboutsomeoneelse"][data-conditionalfieldvalue="true"]').hide();
                }
                else if (qid === "q_injuredpartydeceased" && _qfield_id === "q_injuredpartydeceased") {
                    if (bValue === true) {
                        $('[data-conditionalfieldid="q_injuredpartydeceased"][data-conditionalfieldvalue="true"]').show();
                        $('[data-conditionalfieldid="q_injuredpartydeceased"][data-conditionalfieldvalue="false"]').hide();
                    }
                    else {
                        $('[data-conditionalfieldid="q_injuredpartydeceased"][data-conditionalfieldvalue="true"]').hide();
                        $('[data-conditionalfieldid="q_injuredpartydeceased"][data-conditionalfieldvalue="false"]').show();
                    }
                }
                else if (qid === "q_minor" && _qfield_id === "q_minor") {
                    if (bValue === false) $('[data-conditionalfieldid="q_minor"][data-conditionalfieldvalue="false"]').show();
                    else $('[data-conditionalfieldid="q_minor"][data-conditionalfieldvalue="false"]').hide();
                }
                else if (qid === "q_injuredpartyknowyouarecalling" && _qfield_id === "q_injuredpartyknowyouarecalling") {
                    if (bValue === true) {
                        $('[data-conditionalfieldid="q_injuredpartyknowyouarecalling"][data-conditionalfieldvalue="true"]').show();
                        $('[data-conditionalfieldid="q_injuredpartyknowyouarecalling"][data-conditionalfieldvalue="false"]').hide();
                        $('[data-conditionalfieldid="q_injuredpartyisincapacitated"][data-conditionalfieldvalue="true"]').hide();
                    }
                    else {
                        $('[data-conditionalfieldid="q_injuredpartyknowyouarecalling"][data-conditionalfieldvalue="true"]').hide();
                        $('[data-conditionalfieldid="q_injuredpartyknowyouarecalling"][data-conditionalfieldvalue="false"]').show();
                    }
                }
                else if (qid === "q_injuredpartycansigncontract" && _qfield_id === "q_injuredpartycansigncontract") {
                    if (bValue === false) $('[data-conditionalfieldid="q_injuredpartycansigncontract"][data-conditionalfieldvalue="false"]').show();
                    else $('[data-conditionalfieldid="q_injuredpartycansigncontract"][data-conditionalfieldvalue="false"]').hide();
                }
                else if (qid === "q_injuredpartyisincapacitated" && _qfield_id === "q_injuredpartyisincapacitated") {
                    if (bValue === true) $('[data-conditionalfieldid="q_injuredpartyisincapacitated"][data-conditionalfieldvalue="true"]').show();
                    else $('[data-conditionalfieldid="q_injuredpartyisincapacitated"][data-conditionalfieldvalue="true"]').hide();
                }
            }
        }
    },

    stringToBoolean: function (string) {
        switch (string.toLowerCase().trim()) {
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(string);
        }
    },

    LoadingMini: function (div) {
        $(div).html('<div class="mini-loader-div"><div class="mini-loader centered"></div><span>Loading...</span></div>');
    },

    LoadingMiniMessage: function (div,message) {
        $(div).html('<div class="mini-loader-div"><div class="mini-loader centered"></div><span>' + message + '</span></div>');
    },

    Loading: function (divx) {
        $(divx).html('<div class="preloader"><div class="preloaderX"></div></div>');
    },

    getJsDateFromExcel: function (excelTimestamp) {
        const secondsInDay = 24 * 60 * 60;
        const excelEpoch = new Date(1899, 11, 31);
        const excelEpochAsUnixTimestamp = excelEpoch.getTime();
        const missingLeapYearDay = secondsInDay * 1000;
        const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
        const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
        const parsed = excelTimestampAsUnixTimestamp + delta;
        const parsedDate = new Date(parsed);
        return isNaN(parsed) ? null : new Date(parsedDate.setHours(parsedDate.getHours() - 1));
    },

    LoadPartial: function (url, target, requestData, usespinner = true) {
        if (usespinner) {
            bbglobal.LoadingMini(target);
        }
        $.ajax({
            url: url,
            datatype: "html",
            type: "GET",
            data: requestData
        })
        .done(function (data) {
            $(target).html(data);
        })
        .fail(function (errorData) {
            $(target).html(errorData.responseText || 'There was an error');
        });
    },

    MakeDetailReadyByForm: function ($form) {
        //This function is meant to prep a form when it loads up.
        //If the form is a modal form

        if ($form.closest('.modal').hasClass('in') === true) {
            //Find the first enabled control and set focus to it
            $form.find(':input:enabled:visible:not([readonly]):first').focus();
            //Tabbing between fields is very flakey with modals. The only way I could find
            //to keep focus from jumping back to the parent page was to set all tab indexes
            //to 0, and let tabbing work based on the input box's position in the modal page
            $form.find('*[tabindex]').each(function () {
                $(this).attr("tabindex", 0);
            });

            //The backtocalling link isn't relevant in a modal form, so hide it
            if (typeof $('#backtocalling') !== "undefined") {
                $('#backtocalling').hide();
            }
        } else {
            //Set focus to input box with a tab index of 1
            $form.find('input[tabindex=1]').focus();
        }
        $('.date-picker').datepicker();
    },

    ValidateInputFields: function (formid, comparediv, compareTodiv, enablediv) {
        //setting up defaults
        if (bbglobal.GRID_DEBUG_MODE) console.log("ValidateInputFields setDefaults");
        $.validator.setDefaults({
            errorClass: 'help-block',
            highlight: function (element) {
                $(element)
                    .parent()
                    .addClass('has-error has-feedback')
                    .removeClass('has-success has-feedback');
                $(enablediv).prop('disabled', true);
            },
            unhighlight: function (element) {
                $(element)
                    .parent()
                    .addClass('has-success has-feedback')
                    .removeClass('has-error has-feedback');
                if ($(compareTodiv).val() !== '' && $(compareTodiv).val() !== null) {
                    $(enablediv).prop('disabled', false);
                }
            }
        });

        // Creating Rules
        $.validator.addMethod("Blank", $.validator.methods.required, "This field is required");
        $.validator.addMethod("Integer", $.validator.methods.digits, "This field requires an Integer value such as 123 etc");
        $.validator.addMethod("Currency", $.validator.methods.number, "This field requires a decimal value such 123.50 etc");
        $.validator.addMethod("Date", $.validator.methods.datemmddyyyy, "This field requires a date in the format MM/DD/YYYY");
        $.validator.addMethod("Phone", $.validator.methods.phoneUS, "This is not a valid phone number");
        $.validator.addMethod("email", $.validator.methods.email, "This is not a valid email address");
        $.validator.addMethod("zipcode", $.validator.methods.zipcodeUS, "This is not a valid zipcode");
        $.validator.addMethod("password", $.validator.methods.password, "Password must be at least 8 characters long, must contain an upper, a lower letter, a digit, and a non alphanumeric character like (*_ @ %&)");
        $.validator.addMethod("confirmpassword", $.validator.methods.equalTo, "Confirm pasword does not match with the password ");
        $.validator.addMethod("emailcheck",
            function (value, element) {
                var _data = {
                    emailId: value
                };
                //alert(request.term);
                $.ajax({
                    async: false,
                    //type: 'POST',
                    url: '/People/EmailExists',
                    data: _data,
                    datatype: "json",
                    contentType: "application/json; charset=utf-8",
                    success: function (data) {
                        var jdata = JSON.parse(data);
                        if (jdata.Error === false)
                            return false;
                        else
                            return true;
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        alert(errorThrown);
                    }
                });
            }, "Email address is already in use.  Please enter another address or remove it");
        // $.validator.addMethod("maxlength", $.validator.methods.maxlength, $.validator.format("This field requires maximum {0} characters."));
        if (bbglobal.GRID_DEBUG_MODE) console.log("ValidateInputFields rules created");
        //applying rules to the class
        $.validator.addClassRules("BBvalidate-required", { Blank: true });
        $.validator.addClassRules("BBvalidate-integer", { Integer: true });
        $.validator.addClassRules("BBvalidate-currency", { Currency: true });
        $.validator.addClassRules("BBvalidate-date", { Date: true });
        $.validator.addClassRules("BBvalidate-phone", { Phone: true });
        $.validator.addClassRules("BBvalidate-email", { email: true });
        $.validator.addClassRules("BBvalidate-zipcode", { zipcode: true });
        $.validator.addClassRules("BBvalidate-password", { password: true });
        $.validator.addClassRules("BBvalidate-confirmpassword", { confirmpassword: comparediv });
        if (bbglobal.GRID_DEBUG_MODE) console.log("ValidateInputFields rules added");
        //validating on tabout
        $(formid).validate({
            onkeyup: function (element) {
                this.element(element);  // <- "eager validation"
            },
            onfocusout: function (element) {
                this.element(element);  // <- "eager validation"
            }
        });
    },

    scrollToTop: function (isModal) {
        // scroll to the top of the page so messages are visible

        if (isModal) {
            $('#modalCard').animate({
                scrollTop: $(".modal-content").offset().top - $('#mainnavbar').outerHeight()
            }, 1000);
        }

        else {
            $('html, body').animate({
                scrollTop: $("#divContent").offset().top - $('#mainnavbar').outerHeight()
            }, 1000);
        }
    },

    ActionOnly: function (urlaction, _data) {
        $.ajax({
            url: urlaction,
            datatype: "html",
            type: "POST",
            cache: false,
            data: _data
        })
            .done(function (data) {
            })
            .fail(function (errorData) {
            });
    },


    ActionWithMessageCustom: function (urlaction, _data, alertdiv, alertOnErrorOnly) {
        $.ajax({
            url: urlaction,
            datatype: "html",
            type: "POST",
            cache: false,
            data: _data
        })
        .done(function (data) {
            $(alertdiv).removeClass('alert-danger');
            $(alertdiv).addClass('alert-success');
            $(alertdiv).show();
            $(alertdiv).html('<div class="alert alert-success" role="alert">' + data.Message + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
        })
        .fail(function (errorData) {
            $(alertdiv).removeClass('alert-success');
            $(alertdiv).addClass('alert-danger');
            $(alertdiv).show();
            $(alertdiv).html('<div class="alert alert-danger" role="alert">' + 'Save Failed: ' + errorData.responseText + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
        });
    },

    ActionWithMessageCustom_Get: function (urlaction, _data, alertdiv, alertOnErrorOnly) {
        $.ajax({
            url: urlaction,
            datatype: "html",
            type: "GET",
            cache: false,
            data: _data
        })
            .done(function (data) {
                $(alertdiv).removeClass('alert-danger');
                $(alertdiv).addClass('alert-success');
                $(alertdiv).show();
                $(alertdiv).html('<div class="alert alert-success" role="alert">' + data.Message + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
            })
            .fail(function (errorData) {
                $(alertdiv).removeClass('alert-success');
                $(alertdiv).addClass('alert-danger');
                $(alertdiv).show();
                $(alertdiv).html('<div class="alert alert-danger" role="alert">' + 'Save Failed: ' + errorData.responseText + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
            });
    },

    ActionWithMessageCustomJSONAndReturn: function (urlaction, _data, alertdiv, reload, actionmessage, doNothingOnSuccess, callback, sync) {
        var $modal = $('#modalCard');
        var results = false;
        //alert('hit');
        if (actionmessage !== '') {
            $(alertdiv).removeClass('alert-success');
            $(alertdiv).removeClass('alert-danger');
            $(alertdiv).html('<div class="row loading-div"><br /><h2>' + actionmessage + '</h2><br /><i class="fa fa-circle-o-notch fa-4x fa-spin loading-glyph"></i><br /><br /></div>');
        }
        var isAsync = false;
        if (sync === true) {
            //console.log("Running Synchronously");
            isAsync = false;
        }
        $.ajax({
            url: urlaction,
            datatype: "html",
            type: "POST",
            cache: false,
            async: isAsync,
            data: _data
        })
            .done(function (data) {
                //alert('done : ' + data);
                //Sometimes data is a string, sometimes an object.  Need it to be a JSON object.
                var _result = {};
                if (typeof data === 'string') {
                    _result = JSON.parse(data);
                }
                else {
                    _result = data;
                }
                if (_result.Error === false) {
                    if (reload || false === true) {
                        if ($modal.hasClass('shouldcausereload')) {
                            $modal.removeClass('shouldcausereload');
                            $modal.addClass('saveandreload');
                            $modal.modal('hide');
                        }
                        else {
                            $modal.modal('hide');
                            location.reload();
                        }
                    }
                    else {
                        // do something maybe?, just getting rid of an ESLint warning
                    }
                    if (!doNothingOnSuccess) {
                        $(alertdiv).removeClass('alert-danger');
                        $(alertdiv).addClass('alert-success');
                        $(alertdiv).show();
                        $(alertdiv).html('<div class="alert alert-success" role="alert">' + _result.Message + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
                    }
                    if (typeof callback === 'function') {
                        callback();
                    }
                    results = true;
                    return results;
                }
                else {
                    $(alertdiv).removeClass('alert-success');
                    $(alertdiv).addClass('alert-danger');
                    $(alertdiv).show();
                    //console.log('failed');
                    $(alertdiv).html('<div class="alert alert-danger" role="alert">' + 'Save Failed: ' + _result.Message + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
                    results = true;
                    return results;
                }
            })
            .fail(function (errorData) {
                $(alertdiv).removeClass('alert-success');
                $(alertdiv).addClass('alert-danger');
                $(alertdiv).show();
                $(alertdiv).html('<div class="alert alert-danger" role="alert">' + 'Save Failed: ' + errorData.responseText + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
                results = false;
                return results;
            });
        return results;
    },

    ActionWithMessageCustomJSON: function (urlaction, _data, alertdiv, closemodal, actionmessage, doNothingOnSuccess, callback, sync) {
        var $modal = $('#modalCard');
        //alert('hit');
        if (actionmessage !== undefined && actionmessage !== null && actionmessage !== '') {
            $(alertdiv).removeClass('alert-success');
            $(alertdiv).removeClass('alert-danger');
            $(alertdiv).html('<div class="row loading-div"><br /><h2>' + actionmessage + '</h2><br /><i class="fa fa-circle-o-notch fa-4x fa-spin loading-glyph"></i><br /><br /></div>');
        }
        var isAsync = true;
        if (sync === true) {
            //console.log("Running Synchronously");
            isAsync = false;
        }
        $.ajax({
            url: urlaction,
            datatype: "json",
            contentType: "application/json; charset=utf-8",
            type: "POST",
            cache: false,
            async: isAsync,
            data: _data
        })
        .done(function (data) {
            //alert('done : ' + data);
            //Sometimes data is a string, sometimes an object.  Need it to be a JSON object.
            var _result = {};
            if (typeof data === 'string') {
                _result = JSON.parse(data);
            }
            else {
                _result = data;
            }
            if (_result.Error === false) {
                if (closemodal || false === true) {
                    if ($modal.hasClass('shouldcausereload')) {
                        $modal.removeClass('shouldcausereload');
                        $modal.addClass('saveandreload');
                        $modal.modal('hide');
                    }
                    else {
                        $modal.modal('hide');
                        location.reload();
                    }
                }
                else {
                    // do something maybe?, just getting rid of an ESLint warning
                }
                if (!doNothingOnSuccess) {
                    $(alertdiv).removeClass('alert-danger');
                    $(alertdiv).addClass('alert-success');
                    $(alertdiv).show();
                    $(alertdiv).html('<div class="alert alert-success" role="alert">' + _result.Message + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
                }
                if (typeof callback === 'function') {
                    callback();
                }
            }
            else {
                $(alertdiv).removeClass('alert-success');
                $(alertdiv).addClass('alert-danger');
                $(alertdiv).show();
                //console.log('failed');
                $(alertdiv).html('<div class="alert alert-danger" role="alert">' + 'Save Failed: ' + _result.Message + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
            }
        })
        .fail(function (errorData) {
            $(alertdiv).removeClass('alert-success');
            $(alertdiv).addClass('alert-danger');
            $(alertdiv).show();
            $(alertdiv).html('<div class="alert alert-danger" role="alert">' + 'Save Failed: ' + errorData.responseText + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');

        });
    },

    resetConditionalFields: function (value, qid) {
        //main element
        var mainElement = $(document.documentElement).find('[data-conditionalfieldid=' + qid + '].conditionalon_another');
        var showElements = $(document.documentElement).find('[data-conditionalfieldid=' + qid + '][data-conditionalfieldvalue="true"]');
        var hideElements = $(document.documentElement).find('[data-conditionalfieldid=' + qid + '][data-conditionalfieldvalue="false"]');
        // clear any text fields
        if (mainElement.length > 0) {
            for (var i = 0; i < mainElement.length; i++) {
                var _qid = $(mainElement[i]).attr('id');
                var _qfield_id = $(mainElement[i]).data('conditionalfieldid');
                var _qfield_value = $(mainElement[i]).data('conditionalfieldvalue');
                $(mainElement[i]).find(':input[type="text"]').each(function (idx, element) {
                    if ($(element).hasClass('conditionalon_another')) {
                        $(element).val('');
                    }
                    else {
                        $(element).val('');
                    }
                });
                // clear any toggle buttons and hidden value
                $(mainElement[i]).find('.btn-default.btn-toggle').each(function (idx, element) {
                    $(element).removeClass('active');
                    $(element).siblings('input[type="hidden"]').val('');
                });
                // clear any dropdowns
                $(mainElement[i]).find('select').each(function (idx, element) {
                    if ($(element).hasClass('conditionalon_another')) {
                        $(element)[0].selectedindex = 0;
                        $(element).val('');
                    }
                    else {
                        $(element)[0].selectedindex = 0;
                        $(element).val('');
                    }
                });
                // show qid values, upper case!
                if (value === "false") {
                    $(showElements).hide();
                    $(hideElements).show();
                }
                else {
                    $(showElements).show();
                    $(hideElements).hide();
                }
                if (_qid > 0) {
                    var conditionalId = 'q_' + _qid;
                    bbglobal.resetConditionalFields2(conditionalId);
                }
            }
        }
    },

    resetConditionalFields2: function (qid) {
        var mainElement = $(document.documentElement).find('[data-conditionalfieldid=' + qid + '].conditionalon_another');
        if (mainElement.length > 0) {
            // clear any text fields
            $(mainElement).find(':input[type="text"]').each(function (idx, element) {
                $(element).val('');
            });

            // clear any toggle buttons and hidden value
            $(mainElement).find('.btn-default.btn-toggle').each(function (idx, element) {
                $(element).removeClass('active');
                $(element).siblings('input[type="hidden"]').val('');
            });

            // clear any dropdowns
            $(mainElement).find('select').each(function (idx, element) {
                $(element)[0].selectedindex = 0;
                $(element).val('');
            });

            $(mainElement).hide();
        }
    },

    ButtonTogglehighlightSelectedYesNos: function () {
        $('div.btn-group[data-toggle="buttons"]').each(function (idx, element) {
            var value = $(element).find('input[type="hidden"]').val();
            var yes = $(element).find('input[type="radio"][data-value="true"]').parent('span');
            var no = $(element).find('input[type="radio"][data-value="false"]').parent('span');
            if (value.toString().toLowerCase() === "true") {
                yes.addClass('active');
            }
            if (value.toString().toLowerCase() === "false") {
                no.addClass('active');
            }
            //  Look to see if we need to toggle any fields on or off during the load
            var qid = $(element).find('input[type="hidden"]').data("qid");
            value = value.toLowerCase();

            $('[data-conditionalfieldid=' + qid + '][data-conditionalfield_value="' + value + '"]').show();
            $('[data-conditionalfieldid=' + qid + '][data-conditionalfield_value!="' + value + '"]').hide();
        });
    },

    CheckConditionalDropdowns: function () {
        $('.quest-select').each(function (idx, element) {
            var _value = $(element).val();
            _value = _value === null ? '' : _value.toLowerCase();
            var qid = $(element).data("qid");

            $('[data-conditionalfieldid=' + qid + '][data-conditionalfield_value="' + _value + '"]').show();
            $('[data-conditionalfieldid=' + qid + '][data-conditionalfield_value!="' + _value + '"]').hide();
        });
    },

    loadDropDown: function  (dropdown, param1, param2, selected, intakeId) {
        var url;
        var data;

        switch (dropdown) {
            case 'TakataModel':
                url = '/Onboard/GetLookupListModel';
                data = { "make": param1, "IntakeId": intakeId };
                break;
            case 'TakataYear':
                url = '/Onboard/GetLookupListModelYear';
                data = { "make": param1, "model": param2, "IntakeId": intakeId };
                break;
            case 'ACUModel':
                url = '/Onboard/GetLookupListModel';
                data = { "make": param1, "IntakeId": intakeId, "ACUList": true };
                break;
            case 'ACUYear':
                url = '/Onboard/GetLookupListModelYear';
                data = { "make": param1, "model": param2, "IntakeId": intakeId, "ACUList": true };
                break;
            default:
                url = '/Onboard/GetLookupList2';
                data = { "category": param1, "code": param2 };
                break;
        }

        $.ajax({
            url: url,
            dataType: 'json',
            type: 'POST',
            data: data,
            success: function (data) {
                if (data.length > 0) {
                    var ele = $(document).find('.quest-select[oldtitle="' + dropdown + '"]');
                    $(ele).empty();
                    $(ele).append('<option Value="--- Select ---"> --- Select --- </option>');
                    for (i in data) {
                        if (ele.length > 0)
                            $(ele).append('<option Value="' + data[i].Value + '">' + data[i].Text + '</option>');
                    }
                    if (selected !== null) {
                        $(ele).val(selected);
                        $(ele).prop("selected", true);
                    }
                }
            }
        });
    },

    loadDropDownAU: function (dropdown, param1, param2, selected) {
        var url;
        var data;

        switch (dropdown) {
            case 'TakataModelau':
                url = '/Onboard/GetLookupListModel';
                data = { "make": param1, "AUList": true };
                break;
            case 'TakataYearau':
                url = '/Onboard/GetLookupListModelYear';
                data = { "make": param1, "model": param2, "AUList": true };
                break;
        }

        $.ajax({
            url: url,
            dataType: 'json',
            type: 'POST',
            data: data,
            success: function (data) {
                if (data.length > 0) {
                    var ele = $(document).find('.quest-select[oldtitle="' + dropdown + '"]');
                    $(ele).empty();
                    $(ele).append('<option Value="--- Select ---"> --- Select --- </option>');
                    for (i in data) {
                        if (ele.length > 0)
                            $(ele).append('<option Value="' + data[i].Value + '">' + data[i].Text + '</option>');
                    }
                    if (selected !== null) {
                        $(ele).val(selected);
                        $(ele).prop("selected", true);
                    }
                }
            }
        });
    },

    loadDropDown2: function (dropdown, param1, param2, selected) {
        var url = '/Onboard/GetLookupList2';
        var data = { "category": param1, "code": param2 };
        var ele = $(dropdown);

        $.ajax({
            url: url,
            dataType: 'json',
            type: 'POST',
            data: data,
            success: function (data) {
                if (data.length > 0) {
                    $(ele).empty();
                    $(ele).append('<option Value="--- Select ---"> --- Select --- </option>');
                    for (i in data) {
                        if (ele.length > 0)
                            $(ele).append('<option Value="' + data[i].Value + '">' + data[i].Text + '</option>');
                    }
                    if (selected !== null) {
                        $(ele).val(selected);
                        $(ele).prop("selected", true);
                    }
                }
                else {
                    $(ele).empty();
                    $(ele).append('<option Value="--- Select ---"> --- Select --- </option>');
                }
            }
        });
    },

    bindNewModalActions: function(eleName) {
        console.log("bindNewModalActions: " + eleName);
    },

    OpenInNewTab: function (url) {
        var win = window.open(url, '_blank');
        win.focus;
    },

    Download: function (url) {
        window.location.href = url;
    },

    kendo: {
        multiColumnComboBox: {

            /*
             * This makes sure that the grid and the search input stay in sync
             * This is needed when SyncValueAndText(false) but that option
             * is required for preventing the underlying value from getting assigned
             * the user's search input
             */
            onFiltering: function (e) {
                if (e.filter === undefined) {
                    e.preventDefault();
                    this.search(this.text());
                }
            }
        }
    },

    comboLookupWithArchive: function (div, url, includeArchiveCheckbox) {
        var kendodiv = '#' + div;
        var archiveBoxDiv = div + 'archive';
        var archiveBoxTemplate = includeArchiveCheckbox ? '<div> <input id="' + archiveBoxDiv + '" type="checkbox"/><label for="archive" style="font - size: 13px; font - family: Poppins, sans - serif; font - stretch: 100 %; font - style: normal; font - weight: 300">Archived</label> </div>' : '';
        $(kendodiv).kendoComboBox({
            dataSource: {
                transport: {
                    read: {
                        url: url,
                        data: { archived: false },
                        dataType: "json",
                        type: 'Get'
                    }
                }
            },
            dataTextField: "Text",
            dataValueField: "Value",
            headerTemplate: archiveBoxTemplate
        });

        $('#' + archiveBoxDiv).click(function () {
            var dataSource = getData(this.checked);
            var combobox = $(kendodiv).data("kendoComboBox");
            combobox.setDataSource(dataSource);
            setTimeout(function () { combobox.open(); }, 100);

        });

        function getData(checked) {
            return new kendo.data.DataSource({
                transport: {
                    read: {
                        url: url,
                        data: { archived: checked },
                        dataType: "json",
                        type: 'Get'
                    }
                }
            });
        }
    },

    dropDownLookupWithArchive: function (div, url, includeArchiveCheckbox) {
        var kendodiv = '#' + div;
        var archiveBoxDiv = div + 'archive';
        var archiveBoxTemplate = includeArchiveCheckbox ? '<div> <input id="' + archiveBoxDiv + '" type="checkbox"/><label for="archive" style="font - size: 13px; font - family: Poppins, sans - serif; font - stretch: 100 %; font - style: normal; font - weight: 300">Archived</label> </div>' : '';
        $(kendodiv).kendoDropDownList({
            dataSource: {
                transport: {
                    read: {
                        url: url,
                        data: { archived: false },
                        dataType: "json",
                        type: 'Get'
                    }
                }
            },
            dataTextField: "Text",
            dataValueField: "Value",
            headerTemplate: archiveBoxTemplate
        });

        $('#' + archiveBoxDiv).click(function () {
            var dataSource = getData(this.checked);
            var dropdown = $(kendodiv).data("kendoDropDownList");
            dropdown.setDataSource(dataSource);
            setTimeout(function () { dropdown.open(); }, 100);

        });

        function getData(checked) {
            return new kendo.data.DataSource({
                transport: {
                    read: {
                        url: url,
                        data: { archived: checked },
                        dataType: "json",
                        type: 'Get'
                    }
                }
            });
        }
    }

};


// =============================================================================
// main css entry point
// Load all CSS styles. These are automatically loaded into the HTML page
// by Webpack.

import 'bootstrap/dist/css/bootstrap.min.css';

require('../wwwroot/vendor/jqueryui/jquery-ui.css');

require('../wwwroot/vendor/kendo-ui/styles/web/kendo.common.css');
require('../wwwroot/vendor/kendo-ui/styles/web/kendo.bootstrap-v4.css');

require('../wwwroot/css/css/style.css');
require('../wwwroot/css/styles.css');

import 'c3/c3.css';

require('../wwwroot/vendor/gridstack/gridstack.css');
require('../wwwroot/vendor/gridstack/gridstack-extra.css');

require('../wwwroot/vendor/dropzone/dropzone.min.css');
require('../wwwroot/vendor/dropzone/basic.min.css');

require('../wwwroot/css/less/grid.less');
require('../wwwroot/css/less/modal.less');

import 'croppie/croppie.css';

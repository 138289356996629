// FILE CHANGED!
// =============================================================================
// Global configuration.
// This section is generic for the (single page) whole application. You have
// to do this only once.

// Google account itdevbaronbudd@gmail.com
// Project name: Portal
// Project ID: portal-1505917777292
// Google Maps JavasScript API key: AIzaSyD0b9rvuo6h36t88hAdlbzYtpxoo-lEGCU

const $ = require('jquery');
const JSZip = require('jszip');

window.jQuery = $;
window.$ = $;
window.JSZip = JSZip;

require("../wwwroot/vendor/jqueryui/jquery-ui.js");

// bootstrap from node_modules
const bootstrap = require('bootstrap');

// modernizr from node_modules
const Modernizr = require('modernizr');
window.Modernizr = Modernizr;

p2device = "none";
p2orientation = "";

window.checkDeviceSize = function checkDeviceSize() {
    // from the bootstrap docs
    // Extra small devices (portrait phones, less than 576px)
    if (Modernizr.mq('(max-width: 567.98px)')) {
        window.p2device = "xs";
    }
    // Small devices (landscape phones, 576px to 768px)
    else if (Modernizr.mq('(min-width: 576px)' && Modernizr.mq('(max-width: 767.98px)'))) {
        window.p2device = "sm";
    }
    // Medium devices (iPad, 768px to 992px)
    else if (Modernizr.mq('(min-width: 768px)' && Modernizr.mq('(max-width: 991.98px)'))) {
        window.p2device = "md";
    }
    // Large devices (desktops, 992px to 1200px)
    else if (Modernizr.mq('(min-width: 992px)' && Modernizr.mq('(max-width: 1199.98px)'))) {
        window.p2device = "lg";
    }
    // Extra Large devices (desktops, 1200px to infinity and beyond!)
    else if (Modernizr.mq('(min-width: 1200px)')) {
        window.p2device = "xl";
    }
};

window.checkDeviceSize();

window.updateVideo = function updateVideo() {
    if (window.p2device === "lg" || window.p2device === "xl") {
        $('#videobackground').get(0).pause();
        $('#videobackground').prop('autoplay', true);
        $('#videobackground').prop('loop', true);
        $('#videobackground').prop('muted', true);
        $('#backgroundsource').attr('src', '/Video/BB_Splash.mp4');
        $('#videobackground').get(0).load();
        var promise = $('#videobackground').get(0).play();
        if (promise !== undefined) {
            promise.then(_ => {
                // Autoplay started!
            }).catch(error => {
                // Autoplay was prevented.
                // Show a "Play" button so that user can start playback.
            });
        }
    }
    else {
        //$('#videobackground').prop('poster', '/images/BBDesktop_blue.jpg');
    }
};

function handleOrientationChange(evt) {
    if (evt.matches) {
        p2orientation = "portrait";
        /* The viewport is currently in portrait orientation */
    } else {
        p2orientation = "landscape";
        /* The viewport is currently in landscape orientation */
    }
}

// MDN test for orientation changes
var mediaQueryList = window.matchMedia("(orientation: portrait)"); // Create the query list.
// function handleOrientationChange(mql) { ... } // Define a callback function for the event listener.
mediaQueryList.addListener(handleOrientationChange); // Add the callback function as a listener to the query list.

handleOrientationChange(mediaQueryList); // Run the orientation change handler once.

// remember any test you want to add to Modernizr must be all lowercase, argh
var detects = {
    'hasjquery': 'jQuery' in window,
    'p2device': window.p2device,
    'p2orientation': window.p2orientation
};

Modernizr.addTest(detects);

console.log("is jquery running? " + Modernizr.hasjquery);
console.log("jquery version? " + jQuery.fn.jquery);
console.log("p2device? " + Modernizr.p2device);
console.log("p2orientation? " + Modernizr.p2orientation);

// add kendo.all objects
require("../wwwroot/vendor/kendo-ui/js/kendo.custom.js");

require("../wwwroot/vendor/jquery-validate/jquery.validate.js");
require("../wwwroot/vendor/jquery-validate/additional-methods.js");
require("../wwwroot/vendor/jquery-validate-unobtrusive/jquery.validate.unobtrusive.js");

// lodash
const _ = require('lodash');

// slimscrollbar scrollbar JavaScript
require('../wwwroot/vendor/jquery.slimscroll.js');
// Menu sidebar
require('../wwwroot/vendor/sidebarmenu.js');
// stickey kit
require('../wwwroot/vendor/sticky-kit-master/dist/sticky-kit.js');

// sparkline
require('../wwwroot/vendor/jquery-sparkline/jquery.sparkline.js');

// chartist
const chartist = require('chartist');

// c3
const c3 = require('c3');
window.c3 = c3;

// croppie
const croppie = require('croppie');
window.croppie = croppie;

// signature_pad
const SignaturePad = require('signature_pad');
window.SignaturePad = SignaturePad;

// Style switcher
require('../wwwroot/vendor/styleswitcher/jQuery.style.switcher.js');

// Gridstack
require('../wwwroot/vendor/gridstack/gridstack.js');

// dropzone.js
require('../wwwroot/vendor/dropzone/dropzone.js');

// Custom JavaScript
require('../wwwroot/vendor/custom.js');

const signalR = require('@aspnet/signalr');
window.signalR = signalR;

require('../wwwroot/js/bbglobal.js');

require('markjs');